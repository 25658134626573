<template>
  <v-app>
    <v-app-bar
      app
      color="black"
      dark
    >
      <div class="d-flex align-center">
        <v-img 
        width="250px"
        max-width="90%"
        :src="require('./assets/amarillo-logo-1.png')"/>
      </div>
       <v-spacer></v-spacer>
   <div class="d-flex align-center">
   
      <v-btn icon>
        <v-icon color="orange">mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon color="orange">mdi-magnify</v-icon>
      </v-btn>
   </div>
    </v-app-bar>    
    <Hero/>
    <Footer/>
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import Footer from './components/Footer';
import Hero from './components/Hero';

export default {
  name: 'App',

  components: {
  //  HelloWorld,
    Footer,
    Hero
  },

  data: () => ({
    //
  }),
};
</script>

<style>

</style>