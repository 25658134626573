<template>
  <v-footer
    dark
    padless
    app
  >
    <v-card
      width="100%"
      flat
      tile
      color="black"
      class="orange--text text-center"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-2 orange--text"
          icon
        >
          <v-icon size="20px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="orange--text pt-0">
        Evolplast es la marca reservada de Plastics Munne. Fabricación artículos acabados materias plásticas.
        <br/>
        Carrer de la Ciència, 29, 33, Nave 5, Poligono industrial, 08850 Gavà
        <br/>
        Tel: +34 936 333 226
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Powered with <strong :style="{color:'red'}">❤</strong> by 
          <a href="https://telnetgroup.es" target="_blank" :style="{color:'white'}">Telnetgroup</a></strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
  }
</script>