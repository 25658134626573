<template>
    <v-container>
        <v-row align-content="center">
            <v-col cols="12" md="4" class="text-center">
                <v-img 
                contain
                :src="require('../assets/evolplast.png')" />
                <v-card-text>
                {{texts.mailText}}
                </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
                <v-card-text class="text-center">
                        <v-btn 
                        x-large
                        icon
                        dark
                        href="https://goo.gl/maps/kYMjX37DKuFnoLhe7">
                            <v-icon color="blue">
                                mdi-google-maps
                            </v-icon>
                        </v-btn>
                        <h3>
                                Carrer de la Ciència, 29, 33, Nave 5, Poligono industrial, 08850 Gavà, España
                        </h3>
                </v-card-text>
                <v-card-text class="text-center">
                        <v-btn 
                        x-large
                        icon
                        dark
                        href="tel:+34936333226">
                            <v-icon color="green">
                                mdi-phone
                            </v-icon>
                        </v-btn>

                            <h3>Tel: +34 936 333 226</h3>
                        
                </v-card-text>
                <v-card-text class="text-center">
                        <v-btn 
                        x-large
                        icon
                        dark
                        href="mailto:xavi.munne@evolplast.com">
                            <v-icon>
                                mdi-mail
                            </v-icon>
                        </v-btn>
                            <h3>E-Mail: contact@evolplast.com</h3>
                </v-card-text>
      
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props:{
        texts:Object
    }
}
</script>