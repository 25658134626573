<template>
 <v-main>
      <v-carousel
        :cycle="!dialog"
        hide-delimiters
        :show-arrows="false"
        :interval="4000"
      >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
    > 
        <v-overlay absolute>
                <v-row
                class="fill-height"
                align="center"
                justify="center"
                >
                    <div class="display-1 text-center">
                        <v-col  cols="12">
                            <v-img
                            :src="require('../assets/evolplast.png')"
                            contain
                            height="200"
                            />
                        </v-col>
                        <v-col>
                            <h3 
                            class="text-center leandro-font" 
                            :style="{padding: '10px', maxWidth:'80vw', margin: 'auto'}"
                            >
                                {{item.text}}
                            </h3>
                        </v-col>

                        <v-col>
                            <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="orange"
            class="ma-2 black--text"
            x-large
            v-bind="attrs"
            v-on="on"
            @click="language=i"
            >
               {{item.btnText}}
               <v-icon
               right
               dark
               >
                mdi-phone
               </v-icon>
            </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{items[language].btnText}}
        </v-card-title>

        <v-card-text>
            <DialogContent :texts="items[language]"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            {{items[language].closeText}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
                        </v-col>
                    </div>
                </v-row>
        </v-overlay>             
      </v-carousel-item>
  </v-carousel>
 </v-main>
</template>

<script>
import DialogContent from './DialogContent'
  export default {
      components:
      {
          DialogContent
      },
    data () {
      return {
        dialog:false,
        language:0,
        items: [
          {
            src: require('../assets/hero/1.jpg'),
            text: "Page is under construction",
            btnText:"Contact us",
            closeText:"Close",
            mailText:"Choose how to contact us",
            sendText:"Send"
          },
          {
            src: require('../assets/hero/2.jpg'),
            text: "Pagina en construcción",
            btnText:"Contactanos",
            closeText:"Cerrar",
            mailText:"Elige la forma para contactarnos",
            sendText:"Enviar"
          },
          {
            src: require('../assets/hero/3.jpg'),
            text: "Страница разрабатывается",
            btnText:"Контакты",
            closeText:"Закрыть",
            mailText:"Выберите удобный способ связаться",
            sendText:"Отправить"
          },
          {
            src: require('../assets/hero/4.jpg'),
            text: "此页面正在建设中",
            btnText:"联系我们",
            closeText:"关闭",
            mailText:"选择联系我们的方式",
            sendText:"发送"
          },
        ],
      }
    },
  }
</script>
<style>
 @import url('https://fonts.googleapis.com/css?family=Comfortaa');

#app{
  font-family: 'Comfortaa', Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
}
.leandro-font{
  font-family: 'Comfortaa', Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
}
</style>